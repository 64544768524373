.editorCont{
    max-width: 120ch !important;
}

.wrapperClassName {
    background-color: #fff;
    padding: 1rem;
    box-shadow: 0 2px 5px #00000055;
    background: #fff;
    border-radius: 10px;
}

.toolbarClassName{
    border: none;
    border-bottom: 1px solid;
    padding-bottom: 10px;
}

.miniInput{
    max-width: 200px !important;
}

.editorClassName{
    max-height: 60ch !important;
    
}