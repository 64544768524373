@font-face {
    font-family: 'Quicksand';
    src: url('../public/fonts/Quicksand-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
*{
    margin: 0;
    padding: 0;
    
}


body{
    background: #F4F7FE !important;
    font-family: 'Quicksand' !important;
    
    
}


h1, h2, h3{
    font-family: 'Quicksand' !important;

}

.thirdColor{
    color: #008099 !important;
}
.thirdStroke{
    stroke: #008099 !important;
}
.thirdBackground{
    background: #008099 !important;
    color: #fff !important;
}

.card{
    box-shadow: 0 2px 5px #00000055 !important;
    background: #fff !important;
    border-radius: 10px !important;
}
a:not(.sidebar a, .menuLink, .btnlink){
    color: #008099 !important;
    text-decoration: underline !important;
}
.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #008099 !important;
}
.alice-carousel__dots{
    
    margin-top: 0 !important;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn{
    padding: 0 !important;
}

.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover{
    color: #ffffff !important;
    background: #045b6d;
}

.alice-carousel__prev-btn p,
.alice-carousel__next-btn p{
    margin: 0 5px !important;
    font-size: 40px;
    font-weight: bolder;
    background: #008099;
    color: #fff;
    padding: 5px 15px;
    border-radius: 50%;
    line-height: 42px;
    
}
/* #00A3C4 */
.ag-theme-default{
    --ag-row-border-style: dashed !important;
  --ag-row-border-width: 5px !important;
  --ag-row-border-color: rgb(150, 150, 200) !important;
}

.PricingTable {
    background: #000 !important;
    padding: 50px !important;
}

.signInForm{
    display: flex;
    flex-direction: column;
    gap: 20px
}

.skillContainer{
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    margin-top: 10px;
    text-align: center;
}
.skillContainer p{
    text-align: center;
}
.skillBubble{
    background: #008099;
    color: #fff;
    width: auto;
    padding: 5px 10px;
    border-radius: 20px;
    min-width: 4ch;
    text-align: center;
    font-weight:bold
}
.skillBubbleMatched{background: #2f803a ;}
.skillBubbleMissing{background: #c21010 !important;}
.skillBubbleOthers{background: #a0a0a0 !important;}
.skillBubbleMaybe{background:linear-gradient(0.25turn,#008799,#c21010) !important;}

.mustSkill{background: #2f803a ;}
.importantSkill{background: #008799 !important;}
.otherSkill{background: #a0a0a0 !important;}

.matchedContainer .importantBubble{background: #008799 !important;}
.matchedContainer .niceBubble{background: #a0a0a0 !important;}

.missingContainer .importantBubble{background: #ce441a !important;}
.missingContainer .niceBubble{background: #a0a0a0 !important;}
.valueSpan{
    font-size: 15px !important;
    align-self: flex-end;
}

.css-1jrtelv{
    background-color: #008099!important;
}

.css-1ss3utg[aria-selected=true]{
    background-color: #008099!important;
    color: #fff!important;

}

.tooltip {
    position: relative;
  }

.tooltip .tooltiptext {
    visibility: hidden;
    /* width: 120px; */
    padding: 2px 15px;
    font-size: 15px;
    background-color: rgb(95, 95, 95);
    color: #fff;
    text-align: center;
    opacity: 60%;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -25px;
    left: 90%;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  .tooltip:hover{
    background:#c21010;
    cursor:pointer;
  }

  /* CSS */
.custom-scrollbar {
    width: 10px;
  }
  
  .custom-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  .custom-scrollbar-thumb {
    background-color: #00a3c4;
    border-radius: 5px;
  }
  
.noteContBlur:after {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    height: 10%;
    bottom: -0px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
    pointer-events: none;
}