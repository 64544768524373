.sc-cont .desc{
    margin-left: 20px ;
}
.sc-cont .desc-btn{
padding: 1.5rem;
min-width: 150px ;
margin: 0 10px;
background-color: #bfcbda;
border-radius: 5px 5px 0 0 ;
}
.sc-cont .desc-btn:hover{background: #f06a5b ; color: #fff; font-weight: bold;}
.active{background: #DF6557 ;}

.desc-board{
    background: #bdcde4;
    padding: 32px;
    border-radius: 20px;
}

.ss-bubble .bubble{
    height: 100px;
    background: #DF6557;
    color: #fff;
    font-weight: bold;
    width: max-content;
}